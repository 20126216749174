import React, { Suspense } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./view/Login/Login";
import Spinner from "./components/ui/Spinner";
import PaymentList from "./view/Invoices/PaymentList";
import InvoiceView from "./view/Invoices/InvoiceView";
import OperatorLayout from "./layout/OperatorLayout";
import Reports from "./view/Reports/Reports";
import FillForm from "./view/Invoices/FillForm";
import PaymentForms from "./view/Invoices/PaymentForms";
import CsInvoice from "./view/Invoices/CsInvoice";
import UsersList from "./view/Users/UsersList";
import UserCreate from "./view/Users/UserCreate";
import { getToken } from "./utils/GlobalFunctions";

function App () {
    const user_data = getToken();
return (<>
            
<Routes>
    <Route path="/" element={<Login />} />
    <Route path="/" element={<OperatorLayout />} >
        
        <Route path="payments" element={
            <Suspense fallback={<Spinner/>}>
                <PaymentList />
            </Suspense>
        }></Route>

        <Route path="payments/forms" element={
            <Suspense fallback={<Spinner/>}>
                <PaymentForms />
            </Suspense>
        }></Route>
        
        <Route path="payments/forms/:formId" element={
            <Suspense fallback={<Spinner/>}>
                <FillForm />
            </Suspense>
        }></Route>
        
        <Route path="payments/invoice/:invoiceId" element={
            <Suspense fallback={<Spinner/>}>
                <InvoiceView />
            </Suspense>
        }></Route>
        
        <Route path="payments/cs/:invoiceId" element={
            <Suspense fallback={<Spinner/>}>
                <CsInvoice />
            </Suspense>
        }></Route>

        <Route path="users" element={
            <Suspense fallback={<Spinner/>}>
                <UsersList/>
            </Suspense>
        }></Route>

        <Route path="users/new" element={
            <Suspense fallback={<Spinner/>}>
                <UserCreate/>
            </Suspense>
        }></Route>

        {<Route path="reports" element={
            <Suspense fallback={<Spinner/>}>
                < Reports />
            </Suspense>
        }></Route>}

    </Route>
    
    </Routes>
    </>
    )
}

export default App;