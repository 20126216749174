import React, { Component } from "react";
import { t } from "i18next";
import { withRouter } from "../../utils/GlobalFunctions";
import { Form, Input, Select, Row, Col } from "antd";
import { getBaseURL } from "../../utils/axiosRequest";
import InputMask from "react-input-mask";
import { SettingOutlined, LoadingOutlined, PlusCircleOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Option } = Select;


export class UserCreate extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoaded: false,
          data: null,
          requestFailed: false,
        };
    };

    componentDidMount() {
        this.getData();
    };
    
    componentDidUpdate() {};

    getData = ()=>{
        fetch(`${getBaseURL()}users/merchantdata/`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
                this.setState({ isLoaded:true, requestFailed: true, })
                
                //window.alert('Ҳисобнома ёфт нашуд')
            }
          })
              .then((responseJson) => {
                this.setState({ data : responseJson, isLoaded: true });
                
              }); 
    };

    onFinish= ()=>{return null};
    
    
    

render() {
    const {isLoaded, data, } = this.state;
    
return (
<div className="w-full">{!isLoaded 
    ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
    : <div className="p-4 w-1/2">
        <Form
            layout="vertical"
            requiredMark={false}
            onFinish={this.onFinish}
            //onFinishFailed={() => console.log()}
          >
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  name="firstname"
                  label={t("FIRST_NAME")}
                  rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="middlename"
                  label={t("MID_NAME")}
                  rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="lastname"
                  label={t("LAST_NAME")}
                  rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phonenumber"
                  label={t("PHONE_NUMBER")}
                  rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <InputMask
                    className="bg-white px-3 py-1 border w-full"
                    mask={"99-999-9999"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label={`${t("EMAIL")} (${t("USER_LOGIN")})`}
                  rules={[
                    {
                      type: "email",
                      message: t("INCORRECT_EMAIL"),
                    },
                    { required: true, message: t("REQUIRED_MESSAGE") },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="merchant_unit"
                  label='Корхона/ташкилот'
                  rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <Select placeholder={t("CHOOSE_FROM")}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options = {data.units.map((elem, _) =>({
                      key: elem.code,
                      value: elem.code,
                      label: elem?.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="job_title"
                  label={t("JOB")}
                  rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="forms"
                  label={t("FORMS_ACCESS")}
                  // rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <Select mode="multiple" style={{ minWidth: 212, width: "100%" }}
                    options = {data.forms.forms.map((elem) => ({
                        key: elem?.id,
                        value: elem?.id,
                        label: `${elem?.group} ${elem.name?.tj}`,
                      
                    }))}
                  />
                    
                  
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              
              <Col span={12}>
                <Form.Item
                  name="area_id"
                  label={t("REGION")}
                  rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <Select placeholder={t("CHOOSE_FROM")}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options = {data.regions.map((elem) =>({
                      value: elem?.area_id,
                      value: elem?.area_id,
                      label: elem?.name,
                    }))}
                  >
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="address"
                  label={t("ADDRESS")}
                  rules={[{ required: true, message: t("REQUIRED_MESSAGE") }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row className="flex justify-between">
              <button
                htmlType="submit"
                type="primary"
                className="px-6 py-2 bg-[#19779F] text-white text-center rounded-[8px]"
              >
                <div className="flex items-center justify-center">
                  <div className="flex mr-2 items-center">
                    <CheckCircleOutlined color="#fff" />
                  </div>
                  <div>{t("ADD")}</div>
                </div>
              </button>
              <Link to ='/users'
                className="px-6 py-2 bg-red-400 text-white text-center rounded-[8px]"
              >
                <div className="flex items-center justify-center">
                  <div className="flex mr-2 items-center">
                    <StopOutlined color="#fff" />
                  </div>
                  <div>{t("CANCEL")}</div>
                </div>
              </Link>
            </Row>
          </Form>
        
    </div>
}</div>
)
}

}

export default withRouter(UserCreate);