import React, { Component } from "react";
import { t } from "i18next";
import { withRouter } from "../../utils/GlobalFunctions";
import { Avatar, Tag, Table, Popconfirm, Pagination, Input, } from "antd";
import { getBaseURL } from "../../utils/axiosRequest";
import { SettingOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Search } = Input;


export class UsersList extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoaded: false,
          data: null,
          requestFailed: false,
          userforms: null,
          pageSize: 20,
          currentPage: 1,
          searchValue: '',
          tableUpdate: false,
        };
    }

    componentDidMount() {
        this.getUsers();
    }
    
    componentDidUpdate() {}

    getUsers = ()=>{
        fetch(`${getBaseURL()}users/`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
                this.setState({ isLoaded:true, requestFailed: true, })
                
                //window.alert('Ҳисобнома ёфт нашуд')
            }
          })
              .then((responseJson) => {
                this.setState({ data : responseJson, isLoaded: true });
                
              }); 
    }
    onUserOpen=(id)=>{return null}
    disableUser=(status)=>{
        fetch(`${getBaseURL()}users/status`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
            body: JSON.stringify({
                'id': status.id,
                'disable': status.disable,
            })
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
                this.setState({ isLoaded:true, requestFailed: true, })
                
                //window.alert('Ҳисобнома ёфт нашуд')
            }
          })
              .then(() => {
                
                const newData = this.state.data.map((d, _) => {
                        if (d.id === status.id) {
                            return {...d, disable: status.disable,};
                        } 
                        return d;
                    }
                );
                this.setState({ data: newData , isLoaded: true });
                
              }); 
    
    }
    onChangeInArchive=()=>{return null}
    onHandlePageChange = (page, size) => {
        this.setState({currentPage: page, pageSize: size, tableUpdate: true});
       
        fetch(`${getBaseURL()}users/?skip=${(page-1)*size}&limit=${size}&q=${this.state.searchValue}`, 
        {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
                this.setState({ isLoaded:true, requestFailed: true, })
                
                //window.alert('Ҳисобнома ёфт нашуд')
            }
          })
              .then((responseJson) => {
                this.setState({ data : responseJson, isLoaded: true, tableUpdate: false });
                
              });
        //onPageChange(role, size, page, searchValue);
    };

    onSearchValueChange = (value) => {
      this.setState({searchValue: value});
    };
  
    onInvoiceSearch = (value) => {
      this.setState({searchValue: value});
      this.onHandlePageChange( this.state.currentPage, this.state.pageSize);
    };

render() {
    const {isLoaded, data, currentPage, pageSize, searchValue} = this.state;
    const {translation} = this.props;
    
    const optionListUsers = [
        {
          label: t("USERS"),
          value: false,
        },
        {
          label: t("ARCHIVE"),
          value: true,
        },
      ];
      
    const columnsUsers = [
        {
          title: t("USER_NAME"),
          align: "left",
          key: "id",
          width:"35%",
          render: (row) => {
            let user = row?.lastname[0] + row?.firstname[0];
    
            return (
              <div className="flex items-center">
                <div className="w-[40px]">
                  <Avatar className="w-[40px] h-[40px] items-center bg-[#3b8098] cursor-pointer" size="large">
                    {user}
                  </Avatar>
                </div>
                <div className="pl-[20px]">
                    {row?.lastname + " " + row?.firstname}
                </div>
                
              </div>
            );
          },
        },
        {
          title: t("USER_JOB"),
          dataIndex: "job_title",
          align: "center",
          key: "job_title",
          width: "15%",
        },
        {
          title: t("USER_LOGIN"),
          dataIndex: "email",
          align: "center",
          key: "email",
          width: "15%",
        },
        {
          title: t("PHONE_NUMBER"),
          dataIndex: "phonenumber",
          key: "phonenumber",
          align: "center",
          width: "15%",
        },
        {
          title: t("EDIT"),
          align: "center",
          render: (row) => {
            return (
              <>
                <SettingOutlined
                  className="color-[#C9C7D3] cursor-pointer text-[18px]"
                  onClick={() => {
                    this.onUserOpen(row.id);
                  }}
                />
              </>
            );
          },
        },
        ...( [
              {
                title: t("ACTIVE_USERS"),
                align: "center",
                render: (row) => {
                  let disable = row.disable;
    
                  return (
                    <>
                      {disable ? (
                        <Popconfirm
                          title={t("ACTIVATING")}
                          okText={t("OK")}
                          cancelText={t("NO")}
                          onConfirm={() => {
                            this.disableUser({
                              id: row.id,
                              disable: false,
                            });
                          }}
                        >
                          <Tag
                            color="#f50"
                            style={{
                              borderRadius: "5px",
                              padding: "2px 11px",
                              cursor: "pointer",
                            }}
                          >
                            {t("NO")}
                          </Tag>
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          title={t("DEACTIVATING")}
                          okText={t("OK")}
                          cancelText={t("NO")}
                          onConfirm={() =>
                            this.disableUser({
                              id: row.id,
                              disable: true,
                            })
                          }
                        >
                          <Tag
                            color="#87d068"
                            style={{
                              borderRadius: "5px",
                              padding: "2px 15px",
                              cursor: "pointer",
                            }}
                          >
                            {t("OK")}
                          </Tag>
                        </Popconfirm>
                      )}
                    </>
                  );
                },
              },
            ]),
      ];

return (
<div className="w-full">{!isLoaded 
    ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
    : <div className="w-full">
        
        <div className="mt-4">
            <div className="m-2 flex justify-between">
            <Link to ='/users/new' className="flex bg-emerald-600 mr-[2rem] text-white m-2 lg:text-md xl:text-lg sm:px-3 sm:py-1 md:px-3 lg:px-5 xl:px-10 md:py-1 lg:py-1 xl:py-1 whitespace-nowrap rounded-[8px]">
              <div className="flex items-center">
                <div className="flex mr-4 items-center">
                  <PlusCircleOutlined color="#fff" />
                </div>
                <div>{t("NEW_USER")}</div>
              </div>
            </Link>
            <Search
                className="p-4"
                id="searchInvoice"
                allowClear
                onChange={(e)=>this.onSearchValueChange(e.target.value)}
                placeholder={t("INVOICE_SEARCH")}
                onSearch={this.onInvoiceSearch}
                value={searchValue}
                enterButton
            />
            
            </div>

            
            {this.state.tableUpdate ? <>
                <div className="w-full h-[10rem] flex items-center justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
            </>
            
            :<> 
            

            <Table 
                className="md:pl-4"
                dataSource={data ? data : null}
                columns={columnsUsers} 
                pagination={false} 
                sticky
                size="small"
            >
            </Table>
            <Pagination
                className="mt-[2rem] ml-4"
                onChange={this.onHandlePageChange}
                onShowSizeChange={this.onHandlePageChange}
                total={data.stats ? Math.ceil(data.stats.open_qty + data.stats.charged_qty) : 0}
                current={currentPage}
                pageSize={pageSize}
                hideOnSinglePage={true}
              />
            </>
            }
            
        </div>
    </div>
}</div>
)
}

}

export default withRouter(UsersList);