import React, { Component } from "react";
import { withRouter, } from "../../utils/GlobalFunctions";
import { getBaseURL } from "../../utils/axiosRequest";
import { LoadingOutlined, } from '@ant-design/icons';

export class CsInvoice extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          data: null,
          requestFailed: false,
          pdfDoc: null,
          csfIsOpen: false,
          processing: false,
          localFileurl: [{ invoice_number: null, blob_url: null },],
          invoice: {},
        };
    }

    componentDidMount() {
       this.onShowFile(); 
        
    }
    
    componentDidUpdate() {}
    
    

    onShowFile = () => {
        
        const m_json = this.state.localFileurl.find(
          (el) => el["invoice_number"] === this.props.params.invoiceId
        );
        if (m_json !== void 0) {
          this.setState({pdfDoc: m_json["blob_url"], csfIsOpen: true, processing: false, });
          //document.title = t("PAYMENT_DOCUMENT") + " № " + this.props.params.invoiceId;
          return;
        }
        
        let url = `${getBaseURL()}reports/csf868_pdf?invoice_id=${this.props.params.invoiceId}`;
        this.setState({processing: true});
        fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          }).then(async (response) => {
            const blob = await response.blob();
            const m_url = URL.createObjectURL(blob);
            this.setState({pdfDoc: m_url});
            this.setState({localFileurl: [...this.state.localFileurl, 
                {invoice_number: this.props.params.invoiceId, blob_url: m_url,},
            ]});
          });
    
        this.setState({csfIsOpen: true, processing: false, });
        //document.title = t("PAYMENT_DOCUMENT") + " № " + row?.invoice_number;
        //setInvoice(row);
    
        //base64ToBlob( data, 'application/pdf' )));
      };

    


render() {
    const {processing, pdfDoc, } = this.state;
return (
    
<div className="w-full">{processing
    ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
    : 
    <div>
        <div className="md:bg-[#18526B] md:sticky md:top-0 z-[99] flex justify-center p-[1rem] sm:text-base md:text-white uppercase"></div>
        <div className="h-screen w-full overflow-y-auto">
            <div id="app-title"></div>
            <div className="mb-0"></div>
            
           <embed
              title={this.props.params.invoiceId}
              src={`${pdfDoc}#toolbar=1`}
              className="w-full h-[100%]"
            />

          </div>
        
    
        
    </div>
}</div>
)
}

}

export default withRouter(CsInvoice);