import React from "react";
import { t } from "i18next";
import { Component } from "react";
import dayjs from "dayjs";
import { DatePicker, Drawer, Spin, Pagination, Popover, Table, Modal, ConfigProvider} from "antd";
import { LoadingOutlined, FileExcelOutlined, SyncOutlined } from "@ant-design/icons";
import { numberWithSpaces, withRouter } from "../../utils/GlobalFunctions";
import { getBaseURL } from "../../utils/axiosRequest";
import { saveAs } from 'file-saver';
const { RangePicker } = DatePicker;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      processing: true,
      data: null,
      invoiceInfo: null,
      openInvoiceInfo: false,
      requestFailed: false,
      start: dayjs().startOf("month").format("YYYY-MM-DD"),
      end: dayjs().endOf("month").format("YYYY-MM-DD"),
      sv_name: null,
      sv_status: null,
      current_type: null,
      pageSize: 20,
      currentPage: 1,
    };
  }

  componentDidMount() {
    //this.setState({start: moment().startOf("month")});
    //this.setState({end: moment().endOf("month")})
    this.getData();
  }

  componentDidUpdate() {}

  getInvoiceInfo = (dept_id, form_id, type, status, page, size) => {
    let query = ``;
    this.setState({current_type: type, processing: true});
    if (status !== undefined) {
      query += `?status=${status}`;
    }
    if (dept_id !== undefined) {
      query += `&dept_id=${dept_id}`;
    }
    if (form_id !== undefined) {
      query += `&form_id=${form_id}&ftype=${type}`;
    }
    if (this.state.start !== null) {
      query += `&start_date=${this.state.start} 00:00:00`;
    }
    if (this.state.end !== null) {
      query += `&end_date=${this.state.end} 23:59:59`;
    }
    if (page !== null) {
      query += `&skip=${(page - 1) * size}`;
    }
    if (size !== null) {
      query += `&limit=${size}`;
    }

    fetch(`${getBaseURL()}reports/rni/invoice/info${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage["access_token"],
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          this.setState({ requestFailed: true });
        }
      })
      .then((responseJson) => {
        this.setState({
          invoiceInfo: responseJson,
          processing: false,
          openInvoiceInfo: true,
        });
      });
  };

  getData = () => {
    let query = ``;
    if (this.state.start !== null) {
      query += `?start_date=${this.state.start} 00:00:00`;
    }
    if (this.state.end !== null) {
      query += `&end_date=${this.state.end} 23:59:59`;
    }

    fetch(`${getBaseURL()}reports/rni/pivot${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage["access_token"],
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          this.setState({ requestFailed: true });
        }
      })
      .then((responseJson) => {
        this.setState({ data: responseJson, isLoaded: true });
      });
  };

  getInvoice = (id) => {
    this.setState({ processing: true});
    fetch(`${getBaseURL()}invoices/` + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage["access_token"],
      },
    })

    .then((response) => {
      if(response.status === 200){
          return response.json();     
      }else {
          console.log(new Error(response.status))
          this.setState({ isLoaded: true, requestFailed: true, failedCode: response.status })
          
          //window.alert('Ҳисобнома ёфт нашуд')
      }
    })
        .then((responseJson) => {
          //this.setState({ data : responseJson, isLoaded: true });
          this.setState({ processing: false});
          Modal.info({
            width: '60%',  
            title: `Ҳисобнома № ${responseJson.invoice_number}`,
            content: 
            <div className="print:pt-0 print:w-full sm:pt-0 sm:w-full">
        <div className="md:flex w-full print:text-black text-slate-700">
          <div className="flex md:w-[70%] sm:w-full">
            <div className="flex print:ml-0 ml-4 justify-center items-center">
              <img alt='' className="m-auto p-0" src={responseJson.merchant_logo}></img>
            </div>
            <div className="block  m-4 text-center">
              <div className="mx-2 print:text-[11px] print:mx-0 sm:text-[14px] upper font-semibold">{responseJson.merchant_name['tj']}</div>
              {responseJson.merchant_unit_name['tj'] ? 
              <div className="mx-2 print:text-[11px] print:mx-0 text-center upper sm:text-[14px] font-semibold">{responseJson.merchant_unit_name['tj']}</div>
              :<></>
              }
              <div className="mx-2 print:text-[9px] print:mx-0 sm:text-[11px] md:text-[13px] text-center py-[5px]" >
                {responseJson.merchant_address} {`тел: ${responseJson.merchant_phonenumber} email: ${responseJson.merchant_email}`} 
              </div>
            </div>
          </div>
          <div className="flex md:w-[30%] sm:w-full">
              <div className="sm:flex sm:w-full sm:justify-between items-center print:text-black text-slate-800 text-[14px] font-medium">    
                <div className="md:text-[14px] sm:text-[12px] w-full">
                    <div className="w-full sm:block">
                          <div className="print:text-black print:text-[18px] text-[20px] text-slate-600 font-[700]">
                            {`№ ${numberWithSpaces(responseJson.invoice_number)}`}
                          </div>
                          <div className="print:text-black text-slate-600">Сана: {responseJson.create_at}</div>    
                    </div>
                </div>
              </div>
            </div>
        </div>
      
      <div id = "InvoiceLineBreaker2" className="bg-[#04394f] sm:flex h-[1px]" />
            <div>
                  <div className="print:text-black print:font-semibold print:text-[11px] text-center flex bg-white justify-between sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-slate-500">
                    <div className="print:flex block text-left">
                      <div >Пардохткунанда</div>
                      <div className="print:ml-2">{responseJson.bill_to.replace('Пардохткунанда:', 'Насаб:').toUpperCase()}</div>
                    </div>
                    <div className="print:flex block text-right">
                      <div >Рақами мобилӣ</div>
                      <div className="print:ml-2"> {responseJson.customer_phonenumber}</div>
                    </div>
                  </div>
            </div>
            <div className="print:text-black sm:block md:flex bg-white justify-between sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-slate-600">
              <div className="print:text-[12px] text-justify text-[18px] font-[500] md:w-2/3">
                { responseJson.subject['tj']}
                {/*cleanText(data.subject[i18n.language] !== '' ? data.subject[i18n.language] : data.subject['tj'])*/}
              </div>
              <div className="print:text-[16px] sm:w-full text-[24px] font-[800] text-center md:w-1/3 md:text-right">
                {
                   `${numberWithSpaces((responseJson.amount_due/100).toFixed(2))} сомонӣ`
                   
                }
              </div>
            </div>
            
            
              <div className="print:text-black sm:px-[20px] md:px-0 sm:pb-[10px] mb-4 sm:pt-0 md:pt-[10px] text-slate-600">
            
              {responseJson.payments.map ((item, index) =>(
                <div key={index} className="relative z-30 flex border-b-[1px] border-slate-400">
                  <div className="print:text-[12px] w-[85%] text-left">{`${index+1}. ${item.subject}`}</div>
                  <div className={`print:text-[12px] w-[15%] text-right`}>{numberWithSpaces((item.payment_amount/100).toFixed(2))}</div>
                </div>
              ))}
            </div>

        {responseJson.status === 'Charged' ? 
          <div className="stamp_m text-center z-0"> ПАРДОХТ ШУДААСТ</div> 
          :(responseJson.status === 'void' ? <>
              <div className="flex justify-center items-center bg-white p-[20px]" style={{background:"#fff", padding:"20px"}}>
                <div className="void_stamp"> БЕКОР КАРДА ШУДААСТ</div>
              </div>
          </> : <></>)}   
            

            
            
            
  </div>
        });
          
        });

  };

  render() {
    const {
      isLoaded,
      data,
      openInvoiceInfo,
      processing,
      sv_name,
      sv_status,
      currentPage,
      pageSize,
      total_inv,
      formId,
      deptId,
      statusInv,
      current_type,
    } = this.state;
    const { translation } = this.props;

    const columns = [
      {
        title: "Номгӯи корхона / Тавсиф",
        width: "24%",
        dataIndex: "service_name",
        key: "service_name",
        render: (service_name) => {
          return (
            <Popover
              overlayStyle={{ maxWidth: "400px" }}
              content={service_name}
              title={t("GOV_SERVICE")}
              trigger="hover"
            >
              <p className="cursor-text mline-clamp">{service_name}</p>
            </Popover>
          );
        },
      },
      
      {
        title: "Шумора",
        width: "8%",
        align: "center",
        dataIndex: "qty",
        key: "qty",
        render: (qty, row) => {
              return (
                <div className="text-center text-[12px] text-emerald-500">
                  {qty !== 0 ? (
                    <div
                      className="cursor-pointer"
                      onClick={(e) => {
                        this.setState({
                          total_inv: qty,
                          formId: row?.form_id,
                          deptId: row?.dept_id,
                          sv_name: row?.service_name,
                          statusInv: "Charged",
                          sv_status: "Ҳисобномаҳои пардохт шудааст",
                        });
                        this.getInvoiceInfo(
                          row?.dept_id, row?.form_id,
                          row?.type,
                          "Charged",
                          currentPage,
                          pageSize
                        );
                      }}
                    >
                      {numberWithSpaces(qty)}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            },
          
      },

      {
        title: "Маблағ",
        width: "11%",
        align: "center",
        dataIndex: "amount_paid",
        key: "amount_paid",
        render: (amount_paid, row) => {
        return (
                <div className="text-right text-[12px] text-emerald-500">
                  {amount_paid !== 0 ? (
                    <div
                      className="cursor-pointer"
                      onClick={(e) => {
                        this.setState({
                          total_inv: row?.qty,
                          formId: row?.form_id,
                          deptId: row?.dept_id,
                          sv_name: row?.service_name,
                          statusInv: "Charged",
                          sv_status: "Ҳисобномаҳои пардохт шудааст",
                        });
                        this.getInvoiceInfo(
                          row?.dept_id, row?.form_id,
                          row?.type,
                          "Charged",
                          currentPage,
                          pageSize
                        );
                      }}
                    >
                      {numberWithSpaces(amount_paid.toFixed(2))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            },
          
          
      },

      {
        title: "Боҷ",
        width: "11%",
        align: "center",
        dataIndex: "gov_fee",
        key: "gov_fee",
        render: (gov_fee, row) => {
        return (
                <div className="text-right text-[12px] text-emerald-500">
                  {gov_fee !== 0 ? (
                    <div
                      className="cursor-pointer"
                      onClick={(e) => {
                        this.setState({
                          total_inv: row?.qty,
                          formId: row?.form_id,
                          deptId: row?.dept_id,
                          sv_name: row?.service_name,
                          statusInv: "Charged",
                          sv_status: "Ҳисобномаҳои пардохт шудааст",
                        });
                        this.getInvoiceInfo(
                          row?.dept_id, row?.form_id,
                          row?.type,
                          "Charged",
                          currentPage,
                          pageSize
                        );
                      }}
                    >
                      {numberWithSpaces(gov_fee.toFixed(2))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            },
          
          
      },

      {
        title: "Маблағи хизматрасонӣ",
        width: "14%",
        align: "center",
        dataIndex: "service_fee",
        key: "service_fee",
        render: (service_fee, row) => {
        return (
                <div className="text-right text-[12px] text-emerald-500">
                  {service_fee !== 0 ? (
                    <div
                      className="cursor-pointer"
                      onClick={(e) => {
                        this.setState({
                          total_inv: row?.qty,
                          formId: row?.form_id,
                          deptId: row?.dept_id,
                          sv_name: row?.service_name,
                          statusInv: "Charged",
                          sv_status: "Ҳисобномаҳои пардохт шудааст",
                        });
                        this.getInvoiceInfo(
                          row?.dept_id, row?.form_id,
                          row?.type,
                          "Charged",
                          currentPage,
                          pageSize
                        );
                      }}
                    >
                      {numberWithSpaces(service_fee.toFixed(2))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            },
          
          
      },

      {
        title: "КВД \"БММҒ\"",
        width: "11%",
        align: "center",
        dataIndex: "royalty_fee",
        key: "royalty_fee",
        render: (royalty_fee, row) => {
        return (
                <div className="text-right text-[12px] text-emerald-500">
                  {royalty_fee !== 0 ? (
                    <div
                      className="cursor-pointer"
                      onClick={(e) => {
                        this.setState({
                          total_inv: row?.qty,
                          formId: row?.form_id,
                          deptId: row?.dept_id,
                          sv_name: row?.service_name,
                          statusInv: "Charged",
                          sv_status: "Ҳисобномаҳои пардохт шудааст",
                        });
                        this.getInvoiceInfo(
                          row?.dept_id, row?.form_id,
                          row?.type,
                          "Charged",
                          currentPage,
                          pageSize
                        );
                      }}
                    >
                      {numberWithSpaces(royalty_fee.toFixed(2))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            },
          
          
      },

      {
      title: "Комиссияи бонки",
      width: "11%",
      align: "center",
      dataIndex: "bank_fee",
      key: "bank_fee",
      render: (bank_fee, row) => {
      return (
        
              <div className="text-right text-[12px] text-emerald-500">
                {bank_fee !== 0 ? (
                  <div
                    className="cursor-pointer"
                    onClick={(e) => {
                      this.setState({
                        total_inv: row?.qty,
                        formId: row?.form_id,
                        deptId: row?.dept_id,
                        sv_name: row?.service_name,
                        statusInv: "Charged",
                        sv_status: "Ҳисобномаҳои пардохт шудааст",
                      });
                      this.getInvoiceInfo(
                        row?.dept_id, row?.form_id,
                        row?.type,
                        "Charged",
                        currentPage,
                        pageSize
                      );
                    }}
                  >
                    {numberWithSpaces(bank_fee.toFixed(2))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              
            );
          },
        
        
    },
    ];

    const invColumns = [
      {
        title: t("INVOICE_NUMBER"),
        dataIndex: "invoice_number",
        key: "invoice_number",
        //...getColumnSearchProps('invoice_number', t('INVOICE_NUMBER').toLowerCase()),
        render: (account) => {
          return (
            <div className="text-[12px]" onClick={(e) => {this.getInvoice(account)}}>
              {numberWithSpaces(account)}
            </div>);
        },
      },
      {
        title: t("DATE"),
        dataIndex: "create_at",
        key: "create_at",
        width: "12%",
        align: "center",
        render: (date) => {
          return (
            <div className="text-[12px]">
              {dayjs(date).format("DD.MM.YYYY HH:mm")}
            </div>
          );
        },
      },
      {
        title: t("AMOUNT"),
        dataIndex: "amount",
        key: "amount",
        width: "10%",
        align: "center",
        render: (amount) => {
          return (
            <div className="text-[12px]">
              {numberWithSpaces((amount / 100).toFixed(2))}
            </div>
          );
        },
      },
      {
        title: t("PAYER"),
        dataIndex: "fullName",
        key: "fullName",
        //...getColumnSearchProps('fullName', t('PAYER').toLowerCase()),
        render: (name) => {
          return <div className="uppercase text-[12px]">{name}</div>;
        },
      },
      {
        title: 'РМА',
        dataIndex: "tin",
        key: "tin",
        align: 'center',
        //...getColumnSearchProps('fullName', t('PAYER').toLowerCase()),
        render: (tin) => {
          return <div className="uppercase text-[12px]">{tin}</div>;
        },
      },
      {
        title: t("APPLICANT_CONTACTS"),
        dataIndex: "customer_phonenumber",
        key: "customer_phonenumber",
        align: "center",
        //...getColumnSearchProps('customer_phonenumber', t('APPLICANT_CONTACTS').toLowerCase()),
        render: (customer_phonenumber) => {
          return <div className="text-[12px]">{customer_phonenumber}</div>;
        },
      },
      {
        title: t("PERFORMER"),
        dataIndex: "performer",
        key: "performer",
        align: "center",
        //...getColumnSearchProps('customer_phonenumber', t('APPLICANT_CONTACTS').toLowerCase()),
        render: (performer) => {
          return <div className="text-[12px]">{performer}</div>;
        },
      }
    ];

    return (
      <ConfigProvider
        theme = {{"components": {
          "Table": {
            "headerBg": "#19779f",
            "headerColor": "rgba(255, 255, 255, 0.88)"
          }
          }}}
        
      >
      <div className="flex w-full justify-center">
        {!isLoaded ? (
          <div className="w-full flex justify-center"><Spin indicator={antIcon} /></div>
        ) : (
          <div className="block m-2">
            <div className="flex justify-between w-full m-2 sm:flex-col md:flex-col lg:flex-row xl:flex-row sm:items-start md:items-start lg:items-center xl:items-center sm:space-x-0 md:space-x-0 lg:space-x-6 xl:space-x-6 sm:space-y-3 md:space-y-3 lg:space-y-0 xl:space-y-0">
              <div className="flex items-center">
              <div className="m-4 text-sky-800">Давраи ҳисобот</div>
              <RangePicker
                defaultValue = {[dayjs().startOf("month"), dayjs().endOf("month")]}
                onCalendarChange={(val) => {
                  this.setState({ start: dayjs(val[0]).format("YYYY-MM-DD") });
                  this.setState({ end: dayjs(val[1]).format("YYYY-MM-DD") });
                }}
                onOpenChange={(status) => {
                  if (!status) {
                    this.getData();
                  }
                }}
              />
            </div>

  {data ? (
    <div className="flex items-center pr-4">
          <div className="m-4 text-sky-800">Зеркашии ҳисобот</div>
          <button className="flex bg-green-600 text-white mb-2 lg:text-md xl:text-lg 
            sm:px-3 sm:py-2 md:px-3 lg:px-5 xl:px-10 md:py-2 lg:py-2 xl:py-2 
            whitespace-nowrap rounded-[8px] items-center"
            disabled={this.state.excelDisabled}

            onClick={()=>{
            this.setState({ excelDisabled: true });
            fetch(`${getBaseURL()}reports/rni/excel?start_date=${this.state.start} 00:00:00&end_date=${this.state.end} 23:59:59`,
            { 
                method: "GET",
                headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + window.sessionStorage["access_token"]},
                
            }).then(async response => {
              
              if(response.status === 200){
                let filename = response.headers.get('content-disposition');
                filename = filename.split('"')[1];
                const blob = await response.blob();
                saveAs(blob, filename);
                window.alert(`Файли омор ${filename} бор карда шуд.` ); 
              
                  
              } else {
                window.alert(`Дархости хато` )
                
              }

              this.setState({ excelDisabled: false })
              
            })
          }}
          >

            <div className="flex items-center">
              <div className="flex mr-4 items-center">
                {this.state.excelDisabled 
                  ? <SyncOutlined style={{ fontSize: 28, color: "#fff"}} spin />
                  : <FileExcelOutlined color="#fff" />
                }
              </div>
              <div>Excel</div>
            </div>
          </button>
          </div>
          ):(<></>)
        }
            </div>

            <Table
              columns={columns}
              dataSource={data}
              size="small"
              sticky
              bordered
              pagination={false}
              summary={(pageData) => {
                let totalQty = 0;
                let totalAmount = 0;
                let totalGovFee = 0;
                let totalServiceFee = 0;
                let totalRoyaltyFee = 0;
                let totalBankFee = 0;
                pageData.forEach(
                  ({ qty, amount_paid, gov_fee, service_fee, royalty_fee, bank_fee }) => {
                    totalQty += qty;
                    totalAmount += amount_paid;
                    totalGovFee += gov_fee;
                    totalServiceFee += service_fee;
                    totalRoyaltyFee += royalty_fee;
                    totalBankFee += bank_fee;
                  }
                );

                return (
                  <>
                    
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                        <div className="font-semibold text-right">
                          {translation("TOTAL_AMOUNT")}
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <div className="font-semibold text-center">
                          {totalQty}
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <div className="font-semibold text-right">
                          {totalAmount.toFixed(2)}
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <div className="font-semibold text-right">
                          {totalGovFee.toFixed(2)}
                        </div>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={4}>
                        <div className="font-semibold text-right">
                          {totalServiceFee.toFixed(2)}
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <div className="font-semibold text-right">
                          {totalRoyaltyFee.toFixed(2)}
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <div className="font-semibold text-right">
                          {totalBankFee.toFixed(2)}
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    
                  </>
                );
              }}
            />

            <Drawer
              className="w-full"
              placement="top"
              height="100%"
              open={openInvoiceInfo}
              onClose={() => {
                this.setState({ openInvoiceInfo: false });
              }}
              style={{ overflowX: "hidden" }}
              bodyStyle={{ paddingBottom: 80 }}
              getContainer={false}
              destroyOnClose={true}
            >
              {processing ? (
                <Spin indicator={antIcon} />
              ) : (
                <div className="h-[120vh] w-full">
                  <div className="font-semibold">{sv_name}</div>
                  <div className="mb-4 font-semibold">{sv_status}</div>
                  <Pagination
                    className="mt-2 ml-4"
                    onChange={(page, size) => {
                      this.setState({ currentPage: page, pageSize: size });
                      this.getInvoiceInfo(formId, current_type, statusInv, page, size);
                    }}
                    onShowSizeChange={(page, size) => {
                      this.setState({ currentPage: page, pageSize: size });
                      this.getInvoiceInfo(formId, current_type, statusInv, page, size);
                    }}
                    total={Math.ceil(total_inv)}
                    current={currentPage}
                    pageSize={pageSize}
                    hideOnSinglePage={true}
                  />
                  <Table
                    columns={invColumns}
                    dataSource={this.state.invoiceInfo}
                    bordered
                    pagination={false}
                  ></Table>
                </div>
              )}
            </Drawer>
          </div>
        )}
        
        {/*<div className="block text-center">
                <div className="text-[24px]">Саҳифаи "Ҳисобот"</div>
                <div className="text-[24px]">{translation('UNDER_CONSTRUCTION')}</div>
              </div>*/}
      </div>
      </ConfigProvider>
    );
  }
}

export default withRouter(Reports);
